.largeHeading {
	position: relative;
	text-align: left;

	&--above {
		z-index: 2;
	}
	&--below {
		z-index: 0;

		& + .block {
			position: relative;
			z-index: 1;
		}
	}

	h1, h2 {
		font-family: $ff-secondary;
		line-height: (189/170);
		letter-spacing: .16em;
		font-weight: 400;
		display: block;
		text-transform: uppercase;
		color: #fff;
		@include scaling-fs(30px, 132px, $bp-sm-px, $bp-lg-px);
		margin-bottom: -0.35em;
		margin-left: -$marginDefault*.5;
		position: relative;

		@include query_min($bp-desktop) {
			top: calc(var(--largeHeading) * .35em);
		}

		&.largeHeading--multiline {
			margin-bottom: -0.5em;
		}

		@include query_min($bp-sm) {
			margin-left: - $marginDefault;
		}

		@include scaling(-20px, -190px, 'margin-left', $bp-lg-px, $bp-xxl-px, false);

		a.anchor {
			@include fontsize(16px);
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(-100%, 100%);
		}

		a.cpEditLinkInline {
			position: relative;
			display: inline-block;
			line-height: 16px;
			top: -.5em;

			.icon {
				@include fontsize(16px);
			}
		}
	}
}

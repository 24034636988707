$d-topLinkBgSize: 48px;
$fs-topLink: 16px;

.topLink-wrapper,
.scrollLink-wrapper {
	position: fixed;
	right: $marginDefault;
	bottom: $marginDefault;
	width: $d-topLinkBgSize;
	height: $d-topLinkBgSize;
	z-index: 2000;
	display: none;
	pointer-events: none;
	.js & {
		display: block;
		@include opacity(0);
	}

	@include query_max($bp-sm-px - 1px) {
		display: none;
	}

	.no-touch & {
		&:hover {
			@include opacity(1);
		}
	}

	&.fadeIn {
		pointer-events: auto;
	}

	.mobileNavOpen &, .lightboxOpen & {
		display: none;
	}

	button {
		cursor: pointer;
	}

	.lightboxOpen &,
	.mobileNav-isOpen & {
		display: none !important;
	}
}

.topLink, .scrollLink {
	border-radius: 50%;
	font-size: $fs-topLink;
	color: $c-active;
	width: $d-topLinkBgSize;
	height: $d-topLinkBgSize;
	transition: background-color $transitionTime, color $transitionTime;
	//box-shadow: 0 0 10px rgba(#000, .3);
}

.scrollLink {
	background-color: #fff;
	color: $c-active;

	&:hover {
		background-color: $c-active;
		color: #fff;
	}
}

.topLink {
	background-color: $c-lynch;
	color: #fff;

	&:hover {
		background-color: $c-active;
		color: #fff;
	}
}

.scrollLink {
	@extend .floating-animation;
}

.scrollLink-wrapper {
	right: auto;
	left: 50%;
	margin-left: - $d-topLinkBgSize *.5;
	//pointer-events: none;
	opacity: 0;
}

.topLink-wrapper {
	bottom: $marginDefault*4;

	@include query_max(400px) {
		display: none !important;
	}
}

.button {
	border: none;
	text-decoration: none !important;
	display: inline-block;
	text-align: center;
	transition: background-color $transitionTimeFast, color $transitionTimeFast;
	background-color: $c-saltBox;
	color: #fff !important;
	font-family: $ff-secondary;
	font-weight: 500;
	@include fontsize(13px);
	letter-spacing: .2em;
	padding: rems(13px) $paddingDefault*2 rems(14px);
	border-radius: 25px;
	text-transform: uppercase;
	line-height: (25/14);

	@include query_min($bp-desktop) {
		.text & {
			min-width: 200px;
		}
	}

	.icon {
		position: relative;
		top: -1px;
		margin-right: $marginDefault*.25;
	}

	&:hover, .button-puppeteer:hover & {
		background-color: $c-active;
		color: #fff !important;
	}

	&--md {
		@include fontsize(12px);
		padding: rems(11px) rems(31px) rems(12px);
	}

	//---------- buttons with backgrounds inside of them (used by the location map)
	&--withBg {
		position: relative;
		overflow: hidden;
		background-color: transparent;

		@at-root label#{&}, input {
			cursor: pointer;
		}

		input {
			z-index: 2;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
		}

		&:hover, .button-puppeteer:hover &, &.selected {
			background-color: transparent;
			color: #fff !important;

			.button-bg {
				@include opacity(1);
			}
		}
	}
	&-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		@include opacity(.3);
		transition: opacity $transitionTimeFast;
		border-radius: 25px;
	}

	//---------- alt buttons (used by the location map)
	&--alt {
		border-radius: 0;
		background-color: rgba(#000, .25);
		padding: rems(3px) rems(10px);
		color: $c-boulder !important;

		&:hover, .button-puppeteer:hover &, &.selected {
			color: #fff !important;
			background-color: rgba(#000, .25);
		}

		.icon {
			margin-left: $marginDefault*.25;
			margin-right: 0;
			top: -2px;
		}
	}
}

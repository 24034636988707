.header {
	transition: height $transitionTime;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	height: $d-headerHeight--mobile;

	@include query_min($bp-md) {
		height: $d-headerHeight;
	}

	.row {
		height: $d-headerHeight--mobile;
		transition: height $transitionTime;

		@include query_min($bp-md) {
			height: $d-headerHeight;
		}
	}

	&.floating {
		//box-shadow: 0 0 10px rgba(#000, .3);

		@include query_min($bp-md) {
			height: $d-headerHeight--scroll;

			.row {
				height: $d-headerHeight--scroll;
			}
		}
	}

	.overflowHidden &,
	.mobileNav-isOpen & {
		padding-right: var(--scrollbar-width) !important;
	}

	&-logoHolder {
		@include col--size(70%);

		@include query_min($bp-md) {
			width: 160px;
			@include col--fixed;
		}
	}

	&-logo {
		display: inline-block;
		max-width: 100%;
		text-align: left;

		svg {
			display: inline-block;
			height: 41px;
			transition: all $transitionTime;

			html:not(.is-ie11) & {
				width: auto;
			}
		}
	}

	&-nav {
		width: 100%;
	}

	&-content {
		@include col--size(30%);

		@include query_min($bp-md) {
			@include col--fluid;
		}
	}
}

/*
 * WA MediaBox
 *
 * @author WA Studio <www.webarts.name>
 * @author Jiri Hybek <jiri@hybek.cz>
 * @license MIT
 */

/*
 * Containers
 */
.wa-mediabox-overlay {
	margin: 0;
	padding: 0;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #333333;
	background: rgba(20, 20, 20, 0.95);
	z-index: 1000;
	opacity: 0.0;
	transition: opacity 0.4s;
	-webkit-transition: opacity 0.4s;
}

.wa-mediabox-overlay.opened {
	opacity: 1.0;
}

.wa-mediabox-frame {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 0;
	height: 0;
	margin-left: 0;
	margin-top: 0;
	background: #ffffff;
	box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.5);
	transition: width 0.5s, height 0.5s, margin 0.5s;
	-webkit-transition: width 0.5s, height 0.5s, margin 0.5s;
}

.wa-mediabox-container {
	position: relative;
	width: 100%;
	height: 100%;
	opacity: 0.0;
	z-index: 0;
	cursor: pointer;
	overflow: hidden;
	transition: opacity 0.3s;
	-webkit-transition: opacity 0.3s;
}

.wa-mediabox-frame.loaded .wa-mediabox-container {
	opacity: 1.0;
}

.wa-mediabox-container img {
	max-width: 100%;
	cursor: pointer;
}

/*
 * Close button
 */
.wa-mediabox-frame .wa-mediabox-close {
	position: absolute;
	right: -18px;
	top: -18px;
	width: 36px;
	height: 36px;
	padding: 6px;
	background: #444444;
	border: 0px none;
	border-radius: 36px;
	cursor: pointer;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
	transition: background 0.2s, box-shadow 0.2s;
	-webkit-transition: background 0.2s, box-shadow 0.2s;
}

/*.wa-mediabox-frame .wa-mediabox-close:focus {
	outline: 0;
}*/

.wa-mediabox-frame .wa-mediabox-close:hover {
	background: #D32F2F;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
}

.wa-mediabox-frame .wa-mediabox-close svg {
	width: 24px;
	height: 24px;
	fill: #aaaaaa;
	transition: fill 0.2s;
	-webkit-transition: fill 0.2s;
}

.wa-mediabox-frame .wa-mediabox-close:hover svg {
	fill: #ffffff;
}

/*
 * Title
 */
.wa-mediabox-title {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	padding: 0px 52px 0px 14px;
	line-height: 24px;
	font-size: 13px;
	background: #333333;
	background: rgba(0, 0, 0, 0.8);
	color: #ffffff;
	opacity: 0.0;
	transition: opacity 0.3s, padding 0.3s;
	-webkit-transition: opacity 0.3s, padding 0.3s;
}

.wa-mediabox-frame.loaded.has-title .wa-mediabox-title {
	padding: 14px 52px 14px 14px;
	opacity: 1.0;
}

/*
 * Open button
 */
.wa-mediabox-frame .wa-mediabox-open {
	position: absolute;
	right: 10px;
	bottom: 10px;
	padding: 4px;
	display: none;
	opacity: 0.0;
	z-index: 20;
	cursor: pointer;
	background: #ffffff;
	border: 0px none;
	border-radius: 2px;
	transition: opacity 0.3s;
	-webkit-transition: opacity 0.3s;
}

.wa-mediabox-frame .wa-mediabox-open svg {
	width: 24px;
	height: 24px;
	fill: #333333;
}

.wa-mediabox-frame.can-open-in-new .wa-mediabox-open {
	display: block;
}

.wa-mediabox-frame.can-open-in-new:hover .wa-mediabox-open {
	opacity: 0.4;
}

.wa-mediabox-frame.can-open-in-new:hover .wa-mediabox-open:hover {
	opacity: 1.0;
}

/*
 * PREV & NEXT button
 */

.wa-mediabox-prev,
.wa-mediabox-next {
	position: absolute;
	top: 50%;
	width: 74px;
	height: 74px;
	padding: 5px;
	margin-top: -32px;
	border: 0px none;
	background: transparent;
	cursor: pointer;
	opacity: 0.0;
	transition: opacity 0.3s;
	-webkit-transition: opacity 0.3s;
}

.wa-mediabox-prev { left: -80px; }
.wa-mediabox-next { right: -80px; }

.wa-mediabox-frame.has-prev .wa-mediabox-prev {
	opacity: 1.0;
}

.wa-mediabox-frame.has-next .wa-mediabox-next {
	opacity: 1.0;
}

/*.wa-mediabox-prev:focus,
.wa-mediabox-next:focus {
	outline: 0;
}*/

.wa-mediabox-prev svg,
.wa-mediabox-next svg {
	width: 64px;
	height: 64px;
	fill: #999999;
	transition: fill 0.2s;
	-webkit-transition: fill 0.2s;
}

.wa-mediabox-prev:hover svg,
.wa-mediabox-next:hover svg {
	fill: #ffffff;
}

@media all and (max-width: 479px) {

	.wa-mediabox-prev { left: -40px; }
	.wa-mediabox-next { right: -40px; }

	.wa-mediabox-prev,
	.wa-mediabox-next {
		width: 42px;
		height: 42px;
		margin-top: -21px;
	}

	.wa-mediabox-prev svg,
	.wa-mediabox-next svg {
		width: 32px;
		height: 32px;
	}

}

/*
 * PRELOADER
 */
.wa-mediabox-preloader {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 64px;
	height: 64px;
	margin-left: -32px;
	margin-top: -32px;
	opacity: 1.0;
	transition: opacity 0.5s;
	-webkit-transition: opacity 0.5s;
}

.wa-mediabox-preloader.hidden {
	opacity: 0.0;
}

.wa-mediabox-preloader .wa-mediabox-preloader-wrap {
	box-sizing: border-box;
	position: relative;
	display: inline-block;
	width: 64px;
	height: 64px;
	-webkit-animation: wa-mediabox-preloader-container-rotate 1568ms linear infinite;
	animation: wa-mediabox-preloader-container-rotate 1568ms linear infinite;
}

.wa-mediabox-preloader .wa-mediabox-preloader-spinner {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 1;
	border-color: #2196F3;
	-webkit-animation: wa-mediabox-preloader-fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
	animation: wa-mediabox-preloader-fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
	box-sizing: inherit;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper {
	display: inline-block;
	position: relative;
	width: 50%;
	height: 100%;
	overflow: hidden;
	border-color: inherit;
	box-sizing: inherit;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper.left {
	float: left;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper.right {
	float: right;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper .wa-mediabox-preloader-circle {
	width: 200%;
	height: 100%;
	border-width: 5px;
	border-style: solid;
	border-color: inherit;
	border-bottom-color: transparent !important;
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	box-sizing: inherit;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper.left .wa-mediabox-preloader-circle {
	left: 0;
	border-right-color: transparent !important;
	-webkit-transform: rotate(129deg);
	transform: rotate(129deg);
	-webkit-animation: wa-mediabox-preloader-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
	animation: wa-mediabox-preloader-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper.right .wa-mediabox-preloader-circle {
	left: -100%;
	border-left-color: transparent !important;
	-webkit-transform: rotate(-129deg);
	transform: rotate(-129deg);
	-webkit-animation: wa-mediabox-preloader-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
	animation: wa-mediabox-preloader-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.wa-mediabox-preloader .wa-mediabox-preloader-patch {
	position: absolute;
	top: 0;
	left: 45%;
	width: 10%;
	height: 100%;
	overflow: hidden;
	border-color: inherit;
}

.wa-mediabox-preloader .wa-mediabox-preloader-patch .wa-mediabox-preloader-circle {
	width: 1000%;
	left: -450%;
}

@-webkit-keyframes wa-mediabox-preloader-container-rotate {
	to { -webkit-transform: rotate(360deg); }
}

@keyframes wa-mediabox-preloader-container-rotate {
	to { transform: rotate(360deg); }
}

@-webkit-keyframes wa-mediabox-preloader-fill-unfill-rotate {
	12.5% { -webkit-transform: rotate(135deg); }
	25%   { -webkit-transform: rotate(270deg); }
	37.5% { -webkit-transform: rotate(405deg); }
	50%   { -webkit-transform: rotate(540deg); }
	62.5% { -webkit-transform: rotate(675deg); }
	75%   { -webkit-transform: rotate(810deg); }
	87.5% { -webkit-transform: rotate(945deg); }
	to   { -webkit-transform: rotate(1080deg); }
}

@keyframes wa-mediabox-preloader-fill-unfill-rotate {
	12.5% { transform: rotate(135deg); }
	25%   { transform: rotate(270deg); }
	37.5% { transform: rotate(405deg); }
	50%   { transform: rotate(540deg); }
	62.5% { transform: rotate(675deg); }
	75%   { transform: rotate(810deg); }
	87.5% { transform: rotate(945deg); }
	to    { transform: rotate(1080deg); }
}

@-webkit-keyframes wa-mediabox-preloader-blue-fade-in-out {
	from { opacity: 1; }
	25%  { opacity: 1; }
	26%  { opacity: 0; }
	89%  { opacity: 0; }
	90%  { opacity: 1; }
	100% { opacity: 1; }
}

@keyframes wa-mediabox-preloader-blue-fade-in-out {
	from { opacity: 1; }
	25% { opacity: 1; }
	26% { opacity: 0; }
	89% { opacity: 0; }
	90% { opacity: 1; }
	100% { opacity: 1; }
}

@-webkit-keyframes wa-mediabox-preloader-left-spin {
	from { -webkit-transform: rotate(130deg); }
	50% { -webkit-transform: rotate(-5deg); }
	to { -webkit-transform: rotate(130deg); }
}

@keyframes wa-mediabox-preloader-left-spin {
	from { transform: rotate(130deg); }
	50% { transform: rotate(-5deg); }
	to { transform: rotate(130deg); }
}

@-webkit-keyframes wa-mediabox-preloader-right-spin {
	from { -webkit-transform: rotate(-130deg); }
	50% { -webkit-transform: rotate(5deg); }
	to { -webkit-transform: rotate(-130deg); }
}

@keyframes wa-mediabox-preloader-right-spin {
	from { transform: rotate(-130deg); }
	50% { transform: rotate(5deg); }
	to { transform: rotate(-130deg); }
}

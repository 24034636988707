.tabularContent {
	.gutters {
		margin-top: - $marginDefault*2;
	}

	&-content {
		@include fontsize(14px);
		letter-spacing: .05em;
		line-height: (25/14);
	}

	.row {
		margin-top: $marginDefault;
	}

	.divider {
		margin: $marginDefault*2 0 $marginDefault;
	}

	&-rowHeading {
		@include col--size(100%);
		margin-top: $marginDefault;
		margin-bottom: - $marginDefault*.5;
	}

	a {
		color: $c-text;
		text-decoration: underline;

		&:hover {
			color: $c-active;
		}
	}

	&-col {
		margin-top: $marginDefault;
		@include col--size(100%);

		@include query_min($bp-mid) {
			@include col--size(50%);
		}

		@include query_min($bp-desktop) {
			@include col--size(33.3333%);
		}

		h2 {
			margin-bottom: - $marginDefault*.5;
		}
	}
}

.videoImageLink {
	position: relative;

	&:before {
		content: '';
		background-color: #fff;
		position: absolute;
		width: 46px;
		height: 46px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		z-index: 2;
		@include opacity(.6);
		transition: opacity $transitionTime;
		box-shadow: 0 1px 27px 0 rgba(#000, 0.75);

		@include query_min($bp-desktop) {
			width: 93px;
			height: 93px;
			box-shadow: 0 1px 54px 0 rgba(#000, 0.75);
		}
	}

	.icon {
		@include fontsize(25px);
		position: absolute;
		top: 50%;
		left: 50%;
		transition: color $transitionTime;
		transform: translate(-50%,-50%);
		color: #fff;
		z-index: 3;
		margin-left: 3px;

		@include query_min($bp-desktop) {
			@include fontsize(50px);
			margin-left: 5px;
		}
	}

	@at-root a#{&} {
		&:hover {
			.icon {
				color: $c-active;
			}

			&:before {
				//@include opacity(.8);
			}
		}
	}
}

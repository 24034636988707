.slides--gallery {
	.flickity-slider {
		transform: none !important;
	}

	.slide {
		left: 0 !important;
		opacity: 0;
		transition: opacity $transitionTimeSlider ease-in;
		z-index: -1;

		&.is-selected {
			opacity: 1;
			z-index: 0;
		}
	}
}

@keyframes swipe {
	0%, 100% {
		transform: translate(-50%, -16%) rotate(0);
		opacity: 0;
	}
	10% {
		transform: translate(-50%, -16%) rotate(0);
		opacity: 1;
	}
	30% {
		transform: translate(-50%, -16%) rotate(90deg);
		opacity: 1;
	}
	40% {
		transform: translate(-50%, -16%) rotate(90deg);
		opacity: 0;
	}
}

.virtualTour-wrapper {
	width: 100%;
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: percentage(550/1200);
	}

	> div {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100%;
		height: 100.5% !important;
		z-index: 1;
	}
}

.virtualTour-button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;

	.icon-swiping-arrow {
		@include fontsize(37px);
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 1;
		transform: translate(-50%, -100%);

		@include query_min($bp-mid) {
			@include fontsize(74px);
		}
	}

	.icon-swiping-hand {
		@include fontsize(37px);
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 2;
		animation: swipe 4s linear infinite both;

		@include query_min($bp-mid) {
			@include fontsize(74px);
		}
	}
}

.virtualTour-inner {
	.backgroundImage {
		z-index: 1;
	}

	p {
		position: relative;
		z-index: 2;
		background-color: #fff;
		text-align: center;
		margin: $marginDefault;
	}
}

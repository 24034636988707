@import "mixins/ifNotEmpty";
@import "mixins/rems";
@import "mixins/flex";

//---------- default variables ----------
//prefix
$fg-prefix: 'fg-' !default;

//column count
$fg-columns: 12 !default;

//breakpoints
$fg-breakpoints: (
	sm: rems(320px),
	mid: rems(520px),
	desktop: rems(768px),
	md: rems(1024px),
	lg: rems(1200px)
) !default;

//prepend xs to the breakpoints
$fg-breakpoints: map_merge((xs:0), $fg-breakpoints);

//---------- row base ----------
.#{$fg-prefix}row {
	@include row();
}

//---------- col base ----------
.#{$fg-prefix}col {
	@include col();
}

//---------- default gutters ----------
.#{$fg-prefix}gutters {
	@include gutterSetup(20px);
}

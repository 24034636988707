// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//---------- colors ----------
$c-flamePea: #dc6b2f;
$c-saltBox: #6f5f7b;
$c-lynch: #688197;
$c-viridianGreen: #5c7f71;
$c-mineShaft: #2d2d2d; //text, main nav
$c-silverChalice: #acacac; //sub nav
$c-mercury: #e7e7e7; //page background, mobile border, close button background
$c-tundora: #474747; //footer background, video lightbox bg (95% opacity)
$c-gray: #8d8d8d; //footer text, site credit
$c-gray2: #7e7e7e; //horizontal listings divider, stats border
$c-silverChalice2: #a1a1a1; //footer sub nav
$c-nobel: #b4b4b4; //close button text, directions input text, floors heading
$c-gallery: #f0f0f0; //background image bg color
$c-scorpion: #5e5e5e; //show all map button
$c-boulder: #7c7c7c; //alt button color
$c-alto: #d2d2d2; //floors RSF, disabled floor

//social icons
$c-pinterest: #BD081C;
$c-facebook: #3B5998;
$c-twitter: #1DA1F2;
$c-linkedIn: #0077B5;
$c-instagram: #E4405F;

$c-active: $c-flamePea;

//text color
$c-text: $c-mineShaft;

//body bg
$c-bodyBg: $c-mercury;

//borders
$c-border: $c-gray2;

//forms
$c-error: #cb0000;

//---------- selection background color ----------
$c-selection: $c-saltBox;

//---------- foreground colors ----------
$arr-foregrounds: (

);

//---------- background colors ----------
$arr-backgroundsDark: (
	tundora: $c-tundora,
	tundora95: rgba($c-tundora, .95),
	viridianGreen: $c-viridianGreen,
	flamePea: $c-flamePea,
	saltBox: $c-saltBox,
	lynch: $c-lynch,
	scorpion: $c-scorpion
);
$arr-backgroundsLight: (
	white: #fff,
	gallery: $c-gallery
);

//---------- spacing ----------
$marginDefaultPx: 20px;
$marginDefault: rems($marginDefaultPx);
$paddingDefaultPx: 20px;
$paddingDefault: rems($paddingDefaultPx);

//---------- timing ----------
$transitionTime: .5s; //update in site.js if changed
$transitionTimeFast: .25s; //update in site.js if changed
$transitionTimeSlider: 1s;

//---------- typography ----------
$ff-primary: Montserrat, sans-serif; //400, 400i, 700
$ff-secondary: "Ginora Sans", sans-serif; //400, 600
$ff-tertiary: "Banks Miles Double Line", sans-serif; //400

//font sizes
$fs-default: 16px;
$lh-default: 1;

//---------- breakpoints ----------
$bp-sm-px: 320px;
$bp-mid-px: 520px;
$bp-desktop-px: 768px;
$bp-md-px: 1024px;
$bp-lg-px: 1200px;
$bp-xl-px: 1500px;
$bp-xxl-px: 1920px;
$bp-sm: rems($bp-sm-px);
$bp-mid: rems($bp-mid-px);
$bp-desktop: rems($bp-desktop-px);
$bp-md: rems($bp-md-px);
$bp-lg: rems($bp-lg-px);
$bp-xl: rems($bp-xl-px);
$bp-xxl: rems($bp-xxl-px);

//---------- dimensions ----------
$d-headerHeight--mobile: 60px;
$d-headerHeight: 156px;
$d-headerHeight--scroll: 90px;
$d-fencedContent--text: 900px;
$d-fencedContent: 1020px;
$d-fencedContent--lg: 1645px;
$d-fencedContent--max: 1920px;

//---------- flexbox grid ----------
$fg-prefix: '';
$fg-columns: 2;
$fg-gutterWidth-px: $marginDefaultPx*2;
$fg-breakpoints: (
	sm: $bp-sm,
	mid: $bp-mid,
	desktop: $bp-desktop,
	md: $bp-md,
	lg: $bp-lg,
	xl: $bp-xl,
	//xxl: $bp-xxl
);
$fg-marginDefault-px: $marginDefaultPx;
$fg-paddingDefault-px: $paddingDefaultPx;
$fg-paddingSizes: 4;

html {
	&.lightboxOpen,
	&.mobileNav-isOpen {
		overflow: hidden;
	}
}

body {
	.lightboxOpen &,
	.mobileNav-isOpen & {
		overflow-y: hidden;
		padding-right: var(--scrollbar-width);
	}

	display: flex;
	flex-direction: row;
	flex: 1 0 auto;
}

.holder {
	position: relative;
	width: 100%;

	//gives a white background under the header
	//so it doesn't look weird when transitioning
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		content: '';
		background-color: #fff;
	}

	&, &:before {
		padding-top: $d-headerHeight--mobile;
		//transition: padding-top $transitionTime;

		@include query_min($bp-md) {
			padding-top: $d-headerHeight;
		}

		html.navIsFloating & {
			@include query_min($bp-md) {
				padding-top: $d-headerHeight--scroll;
			}
		}
	}

	html:not(.is-ie11) & {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		min-height: 100vh;
	}
}

.header {
	z-index: 4;
	width: 100%;
	flex: none;
}

.main {
	@extend .clearfix;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex: 1 0 auto;
	z-index: 3;
	position: relative;
	width: 100%;

	&:after {
		content: '\00a0';
		display: block;
		height: 0;
		visibility: hidden;
	}

	.lightboxOpen & {
		z-index: 5;
	}
}

.cpEditLink {
	margin-top: auto;

	.full & {
		margin-top: 0;
	}
}

.footer {
	flex: none;
	margin-top: auto;
	position: relative;
	z-index: 2;
}

//---------- wrapper ----------
.wrapper {
	box-sizing: border-box;
	width: 100%;
	max-width: rems($d-fencedContent--max) - $paddingDefault*2;
	margin: 0 auto;
}

//---------- containers ----------
.container {
	margin-right: auto;
	margin-left: auto;
}

.container--padded,
.container--fluid {
	padding-left: $paddingDefault;
	padding-right: $paddingDefault;

	@include query_min($bp-sm) {
		padding-left: $paddingDefault*2;
		padding-right: $paddingDefault*2;
	}
}
//fluid containers remove padding when self-nested
.container--fluid .container--fluid {
	padding-left: 0;
	padding-right: 0;
}

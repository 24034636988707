.backgroundImage {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100.05%;
	height: 100.05%;
	background-repeat: no-repeat !important;
	background-size: cover;
	transition: opacity $transitionTime;
}

/* fade image in after load */
.lazyloadWithFade {
	&.lazyload,
	&.lazyloading {
		//background-image: url(/assets/svgs/ajax-loader-white.svg) !important;
		background-repeat: no-repeat !important;
		background-position: center center !important;
		background-size: auto !important;
		opacity: .25;

		* {
			visibility: hidden;
		}
	}

	&.lazyloaded {
		opacity: 1;
	}
}

.mediaGrid {
	.row {
		margin-top: - $marginDefault;
	}

	&-item {
		margin-top: $marginDefault;

		&Inner {
			position: relative;
		}

		&--lg {
			@include col--size(100%);

			.mediaGrid-itemInner {
				padding-bottom: percentage(570/1020);
			}
		}

		&--sm {
			@include col--size(50%);

			.mediaGrid-itemInner {
				padding-bottom: 100%;
			}
		}
	}
}

.directionsMap {
	position: relative;


	&-holder {
		position: relative;
	}

	&-map {
		position: relative;
		z-index: 1;
		width: 100%;
		height: 50%;
		height: 50vh;
		min-height: 400px;
		pointer-events: none;
	}

	&-form {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		background-color: #fff;
		transform: translate(-50%, $marginDefault*1.5);
		width: 100%;
		max-width: 340px;

		input, button {
			padding: $paddingDefault*0.75;
		}

		input {
			@include fontsize(12px);
			color: $c-text;
			letter-spacing: .05em;
			line-height: 1;
			width: 100%;

			@include placeholder {
				color: $c-nobel;
			}
		}

		button {
			@include fontsize(16px);
			-webkit-appearance: none;
			color: $c-text;
			transition: background-color $transitionTimeFast, color $transitionTimeFast;

			&:hover, &:focus {
				background-color: $c-active;
				color: #fff;
			}
		}
	}

	&-button {
		position: absolute;
		z-index: 3;
		bottom: $marginDefault*1.5;
		left: 0;
		width: 100%;
		text-align: center;
	}
}

//---------- fenced content ----------
.block--fencedText {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: $d-fencedContent--text;
}

.block--fenced {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: $d-fencedContent;
}

.block--fencedLarge {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: $d-fencedContent--lg;
}

.block--fencedMax {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: $d-fencedContent--max;
}

//---------- spacing overrides ----------
.pageTitle.p--xs-2 + .text.p--xs-2 {
	margin-top: - $marginDefault !important;
}

.p--xs-4 + .largeHeading.p--xs-3 {
	margin-top: - $marginDefault;
}

.largeHeading + .block {
	//margin-top: - $marginDefault*2;
}

.bg--none.p--xs-4 + .text.bg--none.text {
	margin-top: - $marginDefault*2;
}

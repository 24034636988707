.popover {
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#fff, .5);
	transition: opacity $transitionTimeFast;
	opacity: 1;
	overflow-y: auto;
	display: flex;
	flex-direction: column;

	> .wrapper {
		margin-top: auto;
		margin-bottom: auto;
	}

	&-content {
		background-color: #fff;
		position: relative;
		z-index: 2;
		margin: $marginDefault 0;

		.closeButton {
			position: absolute;
			z-index: 3;
			top: $paddingDefault*.5;
			right: $paddingDefault*.5;
		}
	}

	&-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(#fff, .01);
		z-index: 0;
	}

	&-image {
		position: relative;
		z-index: 1;
	}

	&-text {
		position: relative;
		z-index: 2;
		padding: $paddingDefault;
		text-align: center;
		outline: none;

		h2 {
			outline: none;
		}

		@include query_min($bp-sm) {
			padding: $paddingDefault*2;
		}
	}
}

//---------- default variables ----------
//default margin (top and bottom)
$fg-marginDefault-px: 20px !default;
$fg-marginDefault: rems($fg-marginDefault-px);

$fg-marginSizes: 4 !default;

//---------- Generated Classes ----------
@each $name, $size in $fg-breakpoints {
	//xs will not be wrapped in a media query, but the larger sizes will be
	@include fgIfNotEmpty($size) {
		@for $i from 0 through $fg-marginSizes {
			.#{$fg-prefix}m--#{$name}-t#{$i} {
				margin-top: $fg-marginDefault*$i !important;
			}
			.#{$fg-prefix}m--#{$name}-b#{$i} {
				margin-bottom: $fg-marginDefault*$i !important;
			}
		}
		.#{$fg-prefix}m--#{$name}-ta {
			margin-top: auto !important;
		}
		.#{$fg-prefix}m--#{$name}-ba {
			margin-bottom: auto !important;
		}
	}
}

.gallery {

	.row {
		margin: 0;
	}
	.col {
		padding: 0;
		width: 100%;
	}

	&--2 .gallery-item, &--3 > .col {
		@include query_min($bp-sm) {
			@include col--size(50%);
		}
	}

	&-item {
		@extend .col;
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		.backgroundImage {
			z-index: 1;
		}

		&--lg {
			padding-bottom: 100% !important;
		}

		&--sm {
			padding-bottom: 50% !important;
		}
	}
}

//---------- default variables ----------
//prefix
$fg-prefix: 'fg-' !default;

//---------- row base ----------
.#{$fg-prefix}row {
	&--reverse {
		@include row--reverse();
	}
	&--start {
		@include row--start();
	}
	&--center {
		@include row--center();
	}
	&--end {
		@include row--end();
	}
	&--top {
		@include row--top();
	}
	&--middle {
		@include row--middle();
	}
	&--bottom {
		@include row--bottom();
	}
	&--around {
		@include row--around();
	}
	&--between {
		@include row--between();
	}
	&--fullHeight {
		@include row--fullHeight();
	}
}

//---------- col base ----------
.#{$fg-prefix}col {
	&--reverse {
		@include col--reverse();
	}
	&--first {
		@include col--first();
	}
	&--last {
		@include col--last();
	}
	&--start {
		@include col--start();
	}
	&--center {
		@include col--center();
	}
	&--end {
		@include col--end();
	}
	&--top {
		@include col--top();
	}
	&--middle {
		@include col--middle();
	}
	&--bottom {
		@include col--bottom();
	}
	&--fullHeight {
		@include col--fullHeight();
	}
	&--fixed {
		@include col--fixed();
	}
	&--fluid {
		@include col--fluid();
	}
	&--fit {
		@include col--fit();
	}
	&--auto {
		@include col--auto();
	}
}

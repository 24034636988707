//---------- Generated Classes ----------
@each $name, $size in $fg-breakpoints {
	//xs will not be wrapped in a media query, but the larger sizes will be
	@include fgIfNotEmpty($size) {
		.#{$fg-prefix}d--#{$name}-hidden {
			display: none !important;
		}

		.#{$fg-prefix}d--#{$name}-block,
		.#{$fg-prefix}d--#{$name}-visible {
			display: block !important;
		}

		.#{$fg-prefix}d--#{$name}-inline {
			display: inline-block !important;
		}

		.#{$fg-prefix}d--#{$name}-cell {
			display: table-cell !important;
		}

		.#{$fg-prefix}d--#{$name}-flex {
			display: flex !important;
		}
	}
}
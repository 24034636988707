// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
//---------- clearfix ----------
.clearfix {
	&:before,
	&:after {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}

	//zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

//---------- padding/margins ----------
.lobotomize {
	* + * {
		margin-top: $marginDefault;
	}

	//fixes a firefox bug
	br {
		margin: 0 !important;
	}
}

.lobotomize--level {
	> * + * {
		margin-top: $marginDefault;
	}

	//fixes a firefox bug
	br {
		margin: 0 !important;
	}
}

.autoMargins {
	* {
		margin-top: $marginDefault;

		&:first-child {
			margin-top: 0;
		}
	}

	.visuallyHidden + * {
		margin-top: 0;
	}
}

.autoMargins--none {
	* {
		margin-top: 0;
	}
}

//---------- text ----------
%hideText {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

%antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

//---------- images ----------
.img-stretch {
	width: 100%;
}

.backgroundCover {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

//---------- hidden ----------
.hidden,
.js .hidden--js,
html:not(.js) .hidden--noJs {
	display: none !important;
	visibility: hidden;
}

[hidden] {
	display: none;
}

.visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.invisible {
	visibility: hidden;
}

[v-cloak] {
	@extend .visuallyHidden;
}

//---------- clickable ----------
.cursor--pointer {
	cursor: pointer;
}

//---------- loading ----------
%loader {
	display: block;
	height: 200px;
	background-image: url(/assets/svg/ajax-loader.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 40px 40px;
}

.divider {
	height: 2px;
	border: none;
	background-color: $c-gray2;
	width: 100%;

	&--sm {
		width: 35px;
	}
}

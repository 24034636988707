@import "font-size";
@import "media-queries";

$scaling-width-min: 320px !default;
$scaling-width-max: 1920px !default;

/**
Scales any property.
Usage example: `@include scaling(1, 120, 'height');`
@copyright: Aaron Waldon <aaron@causingeffect.com>, January 2017
 */
@mixin scaling($prop-min, $prop-max, $property:'font-size', $width-min:$scaling-width-min, $width-max:$scaling-width-max, $includeDefault:true) {
	$prop-min: strip-unit( $prop-min );
	$prop-max: strip-unit( $prop-max );
	$width-min-plain: strip-unit( $width-min );
	$width-max-plain: strip-unit( $width-max );
	$unit: 'px';

	//default for below the min
	@if $includeDefault {
		#{$property}: $prop-min#{$unit};
	}

	//scale between the width min and max
	@include query_min_max($width-min, $width-max) {
		//the algebra
		$slope : ($prop-max - $prop-min) / ($width-max-plain - $width-min-plain);
		$intercept : $prop-min - ($slope * $width-min-plain);

		//the final property
		#{$property}: calc( #{$slope*100}vw + #{$intercept}#{$unit} );
	}

	//max font size for above the width max
	@include query_min($width-max) {
		#{$property}: $prop-max#{$unit};
	}
}

/**
Scaling font-size.
Usage example: `@include scaling-fs(100px, 600px);`
@copyright: Aaron Waldon <aaron@causingeffect.com>, January 2017
 */
@mixin scaling-fs($fs-min, $fs-max, $width-min:$scaling-width-min, $width-max:$scaling-width-max, $includeDefault:true) {
	$fs-min: strip-unit( $fs-min );
	$fs-max: strip-unit( $fs-max );
	$width-min-plain: strip-unit( $width-min );
	$width-max-plain: strip-unit( $width-max );

	//default font size for below the min
	@if $includeDefault {
		@include fontsize($fs-min);
	}

	//scale between the width min and max
	@include query_min_max($width-min, $width-max) {
		$slope : ($fs-max - $fs-min) / ($width-max-plain - $width-min-plain);
		$intercept : $slope * $width-min-plain - $fs-min;
		font-size: calc( #{$slope}*100vw - #{$intercept}px );
	}

	//max font size for above the width max
	@include query_min($width-max) {
		@include fontsize($fs-max);
	}
}
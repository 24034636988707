.listings {
	//press archive
	&--simple {
		h2 {
			font-family: $ff-secondary;
			font-weight: 400;
			@include scaling-fs(25px, 35px, $bp-sm-px, $bp-md-px);
			line-height: (106/95);
			letter-spacing: .1em;
		}
	}

	//press teasers
	&--thumbed {
		hr {
			margin: $marginDefault*2 0;
		}
	}
}

.listing {
	//upcoming events, event detail page
	&--split {
		@at-root a#{&} {
			text-decoration: none;
			color: $c-text;
			display: block;

			&:hover {
				text-decoration: none;
			}
		}

		.listing-content {
			@include col--size(100%);
			@include query_min($bp-desktop) {
				@include col--size(51%);
				margin-top: -9px;
			}
		}

		.listing-image {
			@include col--size(100%);

			@include query_min($bp-desktop) {
				@include col--size(49%);
			}

			&Inner {
				position: relative;
				width: 100%;
				padding-bottom: percentage(470/500);
				display: block;

				+ .listing-imageInner {
					margin-top: $marginDefault*2;

					@include query_min($bp-lg) {
						margin-top: $marginDefault*3;
					}
				}
			}
		}

		&.listing--contentLeft {
			.listing-content {
				@include query_min($bp-desktop) {
					padding-right: $marginDefault*2;
				}
			}

			.listing-image {
				margin-top: $marginDefault*2;

				@include query_min($bp-desktop) {
					margin-top: 0;
				}
			}
		}

		&.listing--contentRight {
			.listing-content {
				margin-top: $marginDefault*1;

				@include query_min($bp-desktop) {
					margin-top: 0;
				}

				@include query_min($bp-desktop) {
					padding-left: $marginDefault*2;
				}
			}
		}

		& + & {
			margin-top: $marginDefault*3;
		}
	}

	//press archive
	&--simple {
		@at-root a#{&} {
			text-decoration: none;
			color: $c-text;
			display: block;

			&:hover {
				text-decoration: none;

				h1 {
					color: $c-active;
				}
			}
		}

		h1 {
			@include fontsize(20px);
			letter-spacing: .01em;
			text-transform: none;
			font-weight: normal;
			line-height: (28/20);
		}
	}

	//press teasers
	&--thumbed {
		@at-root a#{&} {
			text-decoration: none;
			color: $c-text;
			display: block;

			&:hover {
				text-decoration: none;

				h1 {
					color: $c-active;
				}
			}
		}

		h1 {
			font-family: $ff-secondary;
			font-weight: 500;
			@include scaling-fs(14px, 20px, $bp-sm-px, $bp-md-px);
			line-height: (28/20);
			letter-spacing: .16em;
		}

		.listing-image {
			width: 100px;

			@include query_min($bp-mid) {
				width: 256px;
			}

			img {
				width: 100%;
				max-width: none !important;
				max-height: none !important;
			}
		}

		.listing-content {
			padding-left: $paddingDefault*2;
		}
	}
}

$arr-backgroundsDark: () !default;
$arr-backgroundsLight: () !default;

.bg--dark {
	color: #fff;

	a {
		text-decoration: underline;
	}
}

.bg--light {
	a {
		color: $c-active;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

@each $name, $color in $arr-backgroundsDark {
	.bg--#{$name} {
		background-color: $color;
		color: #fff;

		//auto-collapse successive blocks of the same bg color
		& + &:not(.bg--noCollapse) {
			padding-top: 0 !important;
		}
	}
}

@each $name, $color in $arr-backgroundsLight {
	.bg--#{$name} {
		background-color: $color;
		color: $c-text;

		& + &:not(.bg--noCollapse) {
			padding-top: 0 !important;
		}

		a, .fauxA {
			//color: currentColor;
		}
	}
}

.bg--none + .bg--none {
	padding-top: 0 !important;
}

.wa-mediabox {
	&-overlay {
		background: rgba($c-tundora, .95);
		&.opened {
			z-index: 3000;
		}
	}

	&-frame {
		background: #000;
	}

	&-title {
		@include fontsize(16px);
	}
}

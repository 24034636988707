.imageLinks {
	.row {
		margin: 0;
	}
	.col {
		padding: 0;
		width: 100%;
	}

	&--4 .imageLink, &--2 .imageLink, &--3 > .col {
		@include query_min($bp-desktop) {
			@include col--size(50%);
		}
	}
}

.imageLink {
	@extend .col;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: $paddingDefault*3.5 $paddingDefault*2.5 !important;
	text-decoration: none;
	color: #fff;
	transition: padding $transitionTime;

	@include query_min($bp-xl) {
		padding: $paddingDefault*7 $paddingDefault*5 !important;
	}

	&-content {
		display: block;
		margin-top: auto;
		margin-bottom: auto;
		position: relative;
		z-index: 2;
	}

	&:after {
		content: '';
		background-color: rgba($c-tundora, .3);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		transition: opacity $transitionTime;
	}

	&-text {
		display: block;
		position: relative;
		z-index: 2;
		font-family: $ff-secondary;
		font-weight: 400;
		@include scaling-fs(30px, 60px, $bp-sm-px, $bp-desktop-px);
		line-height: (106/95);
		letter-spacing: .1em;
		text-transform: uppercase;
	}

	&-button {
		display: inline-block;
		position: relative;
		z-index: 3;
		margin-top: $marginDefault*1.5;
	}

	&--lg &-text {
		@include scaling-fs(60px, 95px, $bp-md-px, $bp-xl-px, false);
	}

	&:hover {
		.backgroundImage {
			@include opacity(.9);
		}

		&:after {
			background-color: rgba($c-tundora, .15);
		}
	}
}

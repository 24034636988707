//---------- default variables ----------
//prefix
$fg-prefix: 'fg-' !default;

//---------- grid gutters ----------
@mixin gutterSetup($fg-gutterWidth-px: 20px) {
	$fg-gutterWidth: rems($fg-gutterWidth-px);

	.#{$fg-prefix}row {
		margin-right: $fg-gutterWidth * -0.5;
		margin-left: $fg-gutterWidth * -0.5;
	}

	.#{$fg-prefix}col {
		padding-right: $fg-gutterWidth*.5;
		padding-left: $fg-gutterWidth*.5;
	}
}

//---------- row ----------
@mixin row() {
	box-sizing: border-box;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

//---------- col ----------
@mixin col() {
	box-sizing: border-box;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
	max-width: 100%;
}

//---------- col size ----------
@mixin col--size($percentage:100%) {
	-ms-flex-preferred-size: $percentage;
	flex-basis: $percentage;
	max-width: $percentage;
}

//---------- row modifiers ----------
@mixin row--reverse() {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

@mixin row--start() {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	text-align: left; //fallback
	text-align: start;
}

@mixin row--center() {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

@mixin row--end() {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	text-align: right; //fallback
	text-align: end;
}

@mixin row--top() {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

@mixin row--middle() {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@mixin row--bottom() {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

@mixin row--around() {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

@mixin row--between() {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@mixin row--fullHeight() {
	//height: 100%; //had to remove due to a Safari bug
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

//---------- column modifiers ----------
@mixin col--reverse() {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
}

@mixin col--first() {
	-webkit-box-ordinal-group: 0;
	-ms-flex-order: -1;
	order: -1;
}

@mixin col--last() {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
}

//align
@mixin col--start() {
	text-align: left; //fallback
	text-align: start;
}

@mixin col--center() {
	text-align: center;
}

@mixin col--end() {
	text-align: right; //fallback
	text-align: end;
}

@mixin col--top() {
	align-self: flex-start;
}

@mixin col--middle() {
	align-self: center;
}

@mixin col--bottom() {
	align-self: flex-end;
}

//full height
@mixin col--fullHeight() {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	width: 100%;
}

//advanced sizing
@mixin col--fixed() {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
}

@mixin col--fluid() {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	max-width: 100%;
}

@mixin col--fit() {
	flex: 1 1 fit-content;
	max-width: none;
}

@mixin col--auto() {
	flex: 1 1 auto;
	max-width: none;
}

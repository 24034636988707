//---------- default variables ----------
//default padding (top and bottom)
$fg-paddingDefault-px: 20px !default;
$fg-paddingDefault: rems($fg-paddingDefault-px);

$fg-paddingSizes: 3 !default;

//---------- Generated Classes ----------
@each $name, $size in $fg-breakpoints {
	//xs will not be wrapped in a media query, but the larger sizes will be
	@include fgIfNotEmpty($size) {
		@for $i from 0 through $fg-paddingSizes {
			.#{$fg-prefix}p--#{$name}-#{$i} {
				padding-top: $fg-paddingDefault*$i !important;
				padding-bottom: $fg-paddingDefault*$i !important;
			}
		}
		.#{$fg-prefix}p--#{$name}-t0 {
			padding-top: 0 !important;
		}
		.#{$fg-prefix}p--#{$name}-b0 {
			padding-bottom: 0 !important;
		}
	}
}

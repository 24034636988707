@font-face {
	font-family: 'Ginora Sans';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: local('Ginora Sans'), url('https://fonts.cdnfonts.com/s/18654/Ginóra Sans Regular.woff') format('woff');
}

@font-face {
	font-family: 'Ginora Sans';
	font-display: swap;
	font-style: normal;
	font-weight: 500;
	src: local('Ginora Sans'), url('https://fonts.cdnfonts.com/s/18654/Ginóra Sans Semi Bold.woff') format('woff');
}

/*
      www.OnlineWebFonts.Com
      You must credit the author Copy this link on your web
      <div>Font made from <a href="https://www.onlinewebfonts.com">oNline Web Fonts</a>is licensed by CC BY 3.0</div>
      OR
      <a href="https://www.onlinewebfonts.com">oNline Web Fonts</a>
*/
@font-face {
	font-family: "Banks Miles Double Line";
	font-display: swap;
	font-style: normal;
	font-weight: normal;
	src: url("https://db.onlinewebfonts.com/t/4d8683dbc436404ae093c1e7cbe5d551.eot"); /* IE9*/
	src: url("https://db.onlinewebfonts.com/t/4d8683dbc436404ae093c1e7cbe5d551.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url("https://db.onlinewebfonts.com/t/4d8683dbc436404ae093c1e7cbe5d551.woff2") format("woff2"), /* chrome firefox */
	url("https://db.onlinewebfonts.com/t/4d8683dbc436404ae093c1e7cbe5d551.woff") format("woff"), /* chrome firefox */
	url("https://db.onlinewebfonts.com/t/4d8683dbc436404ae093c1e7cbe5d551.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
	url("https://db.onlinewebfonts.com/t/4d8683dbc436404ae093c1e7cbe5d551.svg#Banks Miles Double Line W01") format("svg"); /* iOS 4.1- */
}

@keyframes fadeInHero {
	from { @include opacity(0); }
	to { @include opacity(1); }
}

.hero {
	position: relative;

	&-container {
		position: relative;
		z-index: 1;
		@include col--fullHeight;
		min-height:  calc(100vh - #{$d-headerHeight--mobile});

		@include query_min($bp-md) {
			min-height:  calc(100vh - #{$d-headerHeight});
		}
	}

	&-content {
		position: relative;
		z-index: 3;
		text-align: center;
		color: #fff;
		font-family: $ff-secondary;
		letter-spacing: .16em;
		line-height: (189/170);
		margin: auto;
		@include scaling-fs(50px, 170px, 320px, 980px);
		animation-name: fadeInHero;
		animation-duration: 2s;
		animation-delay: 2.5s;
		animation-fill-mode: both;
	}

	&-overlay {
		flex: none;
		transition: opacity $transitionTime;
		z-index: 2;
		animation-name: fadeInHero;
		animation-duration: 1s;
		animation-delay: 1.5s;
		background-color: rgba($c-viridianGreen, .5);
		animation-fill-mode: both;
	}

	&-background {
		flex: none;
		z-index: 1;
	}

	&-slides {
		z-index: 1;
	}

	.slide {
		display: block;
		width: 100%;
		height: 100%;
	}

	&-overlay,
	&-background,
	.flickity-viewport,
	.flickity-slider,
	.slides,
	.image {
		position: absolute;
		width: 100%;
		height: 100% !important;
		top: 0;
		left: 0;
	}

	.image--contain {
		.backgroundImage {
			background-size: contain;
		}
	}

	&-video-holder {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		pointer-events: none;
		z-index: 2;
	}

	&-video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}




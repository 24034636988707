$arr-foregrounds: () !default;

//---------- Foreground Colors ----------
@each $name, $color in $arr-foregrounds {
	.c--#{$name} {
		color: $color;
	}
}

//headings
$fs-h1: 13px !default;
$fs-h2: 13px !default;
$fs-h3: 12px !default;
$fs-h4: 12px !default;
$fs-h5: 12px !default;
$fs-h6: 12px !default;

h1, .fauxH1,
h2, .fauxH2,
h3, .fauxH3,
h4, .fauxH4,
h5, .fauxH5,
h6, .fauxH6 {
	font-family: $ff-secondary;
	letter-spacing: .2em;
	line-height: (25/13);
	font-weight: 500;
	text-transform: uppercase;
}

h1, .fauxH1 {

	@include fontsize($fs-h1);
}
h2, .fauxH2 {
	@include fontsize($fs-h2);
}
h3, .fauxH3 {
	@include fontsize($fs-h3);
}
h4, .fauxH4 {
	@include fontsize($fs-h4);
}
h5, .fauxH5 {
	@include fontsize($fs-h5);
}
h6, .fauxH6 {
	@include fontsize($fs-h6);
}

blockquote {
	border-left: 8px solid $c-border;
	padding: 0 0 0 $paddingDefault*1.5;
}

a {
	color: currentColor;
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

.text-content {
	@include fontsize(14px);
	line-height: (25/14);
	letter-spacing: .05em;

	ul, ol {
		padding-left: $paddingDefault;
	}

	li + li {
		margin-top: $marginDefault*.5;
	}

	ul li {
		list-style-type: disc;

		li {
			list-style-type: square;
		}
	}

	ol li {
		list-style-type: decimal;

		li {
			list-style-type: lower-alpha;
		}
	}

	p + h2 {
		margin-top: $marginDefault*2;
	}
}

.text--left {
	text-align: left;
}

.text--right {
	text-align: right;
}

.text--center {
	text-align: center;
}

.text--upper {
	text-transform: uppercase;
}

.text--bold {
	font-weight: bold;
}

.text--slashed {
	text-decoration: line-through;
}

.text--mainNav {
	font-family: $ff-secondary;
	text-transform: uppercase;
	letter-spacing: .2em;
	font-weight: 500;
}

.text--subNav {
	font-family: $ff-secondary;
	text-transform: uppercase;
	letter-spacing: .2em;
	font-weight: 500;
}

.text--time {
	font-family: $ff-secondary;
	font-weight: 400;
	@include scaling-fs(30px, 50px, $bp-sm-px, $bp-md-px);
	line-height: (106/95);
	letter-spacing: .1em;
}

.closeButton {
	&-icon {
		width: 38px;
		height: 38px;
		border-radius: 19px;
		background-color: $c-mercury;
		display: block;
		position: relative;
		margin: 0 auto;

		.icon {
			@include fontsize(16px);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: $c-text;
		}
	}
	&-text {
		@include fontsize(10px);
		font-family: $ff-secondary;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: .2em;
		color: $c-nobel;
		display: block;
		margin: rems(8px) auto 0;
	}

	&--onDark {
		.closeButton-icon {
			background-color: #fff;
		}
		.closeButton-text {
			color: #fff;
		}
	}

	&:hover &-icon {
		background-color: $c-active;

		.icon {
			color: #fff;
		}
	}
}

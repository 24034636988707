table {
	width: 100%;
	text-align: left;
	@include fontsize($fs-default);
	border-collapse: collapse;
	table-layout: fixed;

	th, td {
		padding: $paddingDefault 0 $paddingDefault $paddingDefault;
		border-bottom: 1px solid $c-border;

		@include query_max($bp-mid) {
			padding-left: $paddingDefault*.5;
		}
	}

	tr > :first-child {
		padding-left: 0;
	}

	th {
		font-weight: bold;
	}

	td {

	}
}

.table-scroller-wrapper {
	padding: 0 $paddingDefault $paddingDefault $paddingDefault;
}

.table-scroller {
	overflow-x: auto;
	overflow-y: hidden;
}

.table {
	min-width: 240px;

	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

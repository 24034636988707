.link {
	@include fontsize(12px);
	font-family: $ff-secondary;
	font-weight: 500;
	letter-spacing: .2em;
	line-height: 1.3;
	text-transform: uppercase;
	display: inline-block;
	vertical-align: middle;

	.icon {
		@include fontsize(16px);
		position: relative;
		top: -1px;
	}

	&--iconLeft {
		.icon {
			margin-right: $marginDefault*.5;
		}
	}

	@at-root {
		a#{&}, button#{&} {
			text-decoration: none;
			color: $c-nobel;
			display: block;
			cursor: pointer;

			&:hover {
				text-decoration: none;
				color: $c-active;
			}
		}
	}
}

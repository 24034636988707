// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
//Remove text-shadow in selection highlight: https://twitter.com/miketaylr/status/12228805301
//(These selection rule sets have to be separate.)
::-moz-selection {
	color: white;
	background: $c-selection;
	text-shadow: none;
}

::selection {
	color: white;
	background: $c-selection;
	text-shadow: none;
}

//Remove the gap between audio, canvas, iframes, images, videos and the bottom of their containers
//https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

//Remove default fieldset styles.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

//Allow only vertical resizing of textareas.
textarea {
	resize: vertical;
}

//---------- Base styles ----------
*,
*:after,
*:before {
	box-sizing: border-box;
}

html {
	@extend %antialiased;
	text-rendering: optimizeLegibility;
	overflow-x: hidden;
	overflow-y: scroll;
}

body {
	margin: 0 auto;
	color: $c-text;
	font-family: $ff-primary;
	width: 100%;
	overflow: hidden;
	@include fontsize($fs-default);
	font-weight: 400;
	background-color: $c-bodyBg;
}

a {
	color: currentColor;
	transition: color $transitionTimeFast;
}

i {
	font-style: normal;
}

button {
	border: none;
	background: transparent;
	font-size: inherit;
}

img {
	max-width: 100%;
	vertical-align: middle;
	height: auto;
}

svg {
	max-width: 100%;
	height: auto;
}

section, article {
	display: block;
}

hr, %hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $c-border;
	margin: 0;
	padding: 0;
}

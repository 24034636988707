.calloutText {
	&-text {
		max-width: $d-fencedContent--text;
		@include scaling-fs(12px,31px,$bp-sm-px, $bp-desktop-px);
		text-transform: uppercase;
		line-height: (40/31);
		letter-spacing: .095em;
	}

	sup {
		top: -.2em;
	}
}

.form {

	label {
		display: block;
		margin-bottom: $marginDefault*.5;

		&.label--required:after {
			content: '*';

		}
	}

	&-input,
	input[type=text],
	input[type=email],
	textarea,
	select {
		width: 100%;
		padding: $paddingDefault*.5;
		border: 1px solid $c-border;
		background-color: transparent;
		-webkit-appearance: none;
		border-radius: 0;

		&:disabled {
			@include opacity(.5);
		}
	}

	&-input--inline {
		width: auto;
	}

	textarea {
		min-height: 100px;
	}

	&-errors {
		margin-top: $marginDefault*.5;
		list-style-type: none;
		color: $c-error;
	}
}

//---------- animated ----------
.animated {
	animation-duration: $transitionTime;
	animation-fill-mode: both;
}

.animated.infinite {
	animation-iteration-count: infinite;
}

//---------- fades ----------
@keyframes fadeIn {
	from { @include opacity(0); visibility: hidden; }
	to { @include opacity(1); visibility: visible; }
}

@keyframes fadeOut {
	from { @include opacity(1) }
	to { @include opacity(0) }
}

.fadeIn {
	animation-name: fadeIn;
}
.fadeOut {
	animation-name: fadeOut;
}
//---------- floating effect ----------
//from http://www.justinaguilar.com/animations/#

.floating-animation {
	animation-name: floating;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(10%);
	}
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%);
	}
	50% {
		-webkit-transform: translateY(10%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}
}

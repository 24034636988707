.mobileNav {
	display: flex;
	text-align: left;
	flex-direction: column;
	flex-wrap: nowrap;
	margin: auto;
	position: fixed;
	z-index: 1000;
	top: -200px;
	left: 0;
	width: 100%;
	height: 10px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-top: $paddingDefault*3;
	padding-bottom: $paddingDefault*3;
	transition: opacity $transitionTimeFast;
	opacity: 0;
	background-color: #fff;
	border-top: 2px solid $c-mercury;

	&-container {
		margin-left: auto;
		margin-right: auto;
		display: none;

		> * + * {
			margin-top: $marginDefault*2;
		}

		.mobileNav-isOpen & {
			display: inline-block;
		}
	}

	a {
		text-decoration: none;
	}

	.mobileNav-isOpen & {
		opacity: 1;
		height: calc(100vh - #{$d-headerHeight--mobile});
		top: $d-headerHeight--mobile;

		@include query_min($bp-md) {
			height: calc(100vh - #{$d-headerHeight});
			top: $d-headerHeight;
		}
	}

	&-nav {
		a, button {
			@include fontsize(20px);
			line-height: (22/14);
		}

		li+li {
			margin-top: $marginDefault*.6;
		}
	}

	&-main {
		a, button {
			@extend .text--mainNav;
			//@include fontsize(14px);
			//line-height: (22/14);
			color: #000;
		}

		ul {
			padding: $paddingDefault*.6 0 0 $paddingDefault;
			display: none;

			a, button {
				@extend .text--subNav;
				//@include fontsize(12px);
				//line-height: (22/12);
				color: $c-silverChalice;
				font-weight: 500;
			}
		}
	}

	li {
		&.hasChildren {

			> a {
				padding-right: rems(22px);
				position: relative;

				.icon-caret-down {
					font-size: 70%;
					position: absolute;
					transition: transform $transitionTimeFast;
					color: $c-silverChalice;
					display: inline;
					top: 4px;
					right: 0;
				}
			}

			&.activeParent > ul,
			.toggle--open + ul {
				display: block;
			}

			&.activeParent > a,
			&.activeParent > button,
			> .toggle--open {
				.icon-caret-down {
					transform: rotate(-90deg);
				}
			}
		}
	}

	&-social {
		li {
			display: inline-block;
			@include fontsize(24px);

			+ li {
				margin-left: $marginDefault;
			}
		}
	}

	a, button {
		&:hover, &.selected {
			color: $c-active;
		}
	}
}

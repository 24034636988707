.realEstateListing {
	& + & {
		margin-top: $marginDefault*.5;
	}

	.largeHeading {
		padding-top: 0 !important;
	}

	&-info {
		position: relative;
	}

	&-image {
		position: relative;
		z-index: 1;
		padding-bottom: percentage(675/1020);
	}

	&-actions {
		position: relative;
		z-index: 2;
		width: 200px;
		margin: $marginDefault*2 auto 0;

		@include query_min($bp-mid) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
		}

		.button {
			display: block;
			width: 100%;

			+ .button {
				margin-top: rems(12px);
			}
		}
	}

	.stats {
		padding-top: $paddingDefault*3 !important;
		padding-bottom: 0 !important;
	}
}

.dropdown{
	position: relative;
	display: inline-block;

	input[type=checkbox] {
		display: none;

		&:checked {
			& ~ ul {
				display: block;
			}

			& ~ label {
				background-color: $c-active;
			}
		}
	}

	label {
		cursor: pointer;
		@extend %noSelect;
	}

	ul {
		list-style: none;
		text-align: left;
		width: calc(100% - 50px);
		margin: 0 auto;
		z-index: 1;
		padding: 0;
		display: none;

		li {
			background-color: #fff;

			a {
				text-decoration: none;
				color: $c-text;
				display: inline-block;
				width: 100%;
				padding: rems(13px) $paddingDefault;
				@include fontsize(16px);

				.icon {
					vertical-align: middle;
					margin: 0 $marginDefault*.5 0 0;
				}

				span {
					display: inline-block;
					vertical-align: middle;
					position: relative;
					top: 1px;
					margin: 0;
				}

				&:hover {
					text-decoration: none;
					color: $c-active;
				}
			}

			+ li {
				margin-top: 0;
				border-top: 1px solid $c-border;
			}
		}
	}
}

.footer {
	transition: padding $transitionTime;

	.row {
		@include query_min($bp-desktop) {
			@include row--between;
		}
	}

	.col {
		width: 100%;

		& + .col {
			margin-top: $marginDefault*1.5;

			@include query_min($bp-desktop) {
				margin-top: 0;
			}
		}
	}

	a {
		text-decoration: none;
		&:hover {
			color: $c-active;
		}
	}

	&-meta {
		@include query_min($bp-desktop) {
			@include col--size(30%);
		}
	}

	&-logoHolder {

	}

	&-logo {
		@extend .header-logo;
	}

	&-text {
		@include fontsize(12px);
		line-height: (20/12);
		color: $c-gray;
		margin-top: $marginDefault*1.5;
	}

	&-main {
		@include query_min($bp-desktop) {
			@include col--size(25%);
		}
	}

	&-mainNav {
		@extend .text--mainNav;
		@include fontsize(13px);
		line-height: (25/13);

		li + li {
			margin-top: $marginDefault*.6;
		}
	}

	&-sub {
		@include query_min($bp-desktop) {
			@include col--size(25%);
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
		}
	}

	&-subNav {
		@extend .text--subNav;
		@include fontsize(13px);
		line-height: (25/13);
		color: $c-silverChalice2;

		a {
			&:hover {
				color: #fff;
			}
		}

		li + li {
			margin-top: $marginDefault*.6;
		}
	}

	&-siteCredit {
		padding-top: $paddingDefault*1.5;
		padding-bottom: rems(2px);
		margin-top: auto;
		@include fontsize(12px);
		color: $c-gray;
		align-self: flex-end;
		display: block;
		width: 100%;

		a {
			display: inline-block;
			&:hover {
				color: #fff;
			}
		}
	}

	&-links {
		@include fontsize(24px);
		@include query_min($bp-desktop) {
			@include col--size(20%);
		}

		li {
			display: inline-block;

			+ li {
				margin-left: $marginDefault;
			}
		}
	}

	&-secondaryLogoHolder {
		max-width: 111px;

		& + .social {
			margin-top: $marginDefault;
		}
	}
}

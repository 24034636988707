// Default focus on everything
a:focus, button:focus, input:focus, %defaultFocus {
	outline: 2px solid $c-flamePea;
}


// This will hide the focus indicator if the element receives focus via the mouse,
// but it will still show up on keyboard focus.

.js-focus-visible :focus:not([data-focus-visible-added]) {
	outline: none;
}

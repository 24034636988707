$mobileNav-hamburgerWidth: 40px !default;

.nav {
	a, button {
		text-decoration: none;
		display: inline-block;
		transition: color $transitionTime;
	}

	&-main {
		a, button {
			@extend .text--mainNav;
			@include fontsize(14px);
			line-height: (22/14);
			color: $c-silverChalice;
		}

		li + li {
			@include scaling($marginDefaultPx*1, $marginDefaultPx*2, 'margin-left', $bp-md-px, $bp-lg-px);
		}
	}

	&.nav--home &-main {
		color: $c-mineShaft;

		a, button {
			&:hover {
				color: $c-active;
			}
		}
	}

	&-main ul {
		padding-top: rems(6px);
		color: $c-silverChalice;
		position: absolute;
		top: 100%;
		left: 0;
		width: 50vw;
		text-align: left;
		display: none;

		a, button {
			@extend .text--subNav;
			@include fontsize(12px);
			line-height: (22/12);
		}

		li {
			a, button {
				&:after {
					display: none;
				}
			}
		}

		li + li {
			@include scaling($marginDefaultPx*1, $marginDefaultPx*1.3, 'margin-left', $bp-md-px, $bp-lg-px);
		}
	}

	li {
		display: inline-block;
		position: relative;

		a, button {
			&:after {
				content: '';
				display: block;
				margin-top: 2px;
				height: 2px;
				background-color: transparent;
				transition: background-color $transitionTime;
		}

			&:hover, &.selected {
				color: $c-mineShaft;
			}

			&.selected {
				&:after {
					background-color: $c-active;
				}
			}
		}

		&.hasChildren {

			> a, > button {
				padding-right: rems(16px);
				position: relative;

				.icon-caret-down {
					font-size: rems(10px);
					position: absolute;
					transition: transform $transitionTimeFast;
					color: $c-silverChalice;
					display: inline;
					top: 5px;
					right: 0;
				}
			}

			&.activeParent > ul,
			.toggle--open + ul {
				display: block;
			}

			&.activeParent > a,
			&.activeParent > button,
			> .toggle--open {
				.icon-caret-down {
					transform: rotate(-90deg);
				}
			}
		}
	}

	.layout--fixedHeader .header:not(.floating) & {
		a {
			color: #fff;
		}

		li {
			&:hover {
				> a {

				}
			}
		}
	}

	&-toggle {
		position: relative;
		background-color: transparent;
		cursor: pointer;
		width: $mobileNav-hamburgerWidth;
		padding: 11px $mobileNav-hamburgerWidth 16px 0;

		&-bar {
			&, &:before, &:after {
				cursor: pointer;
				height: 3px;
				width: $mobileNav-hamburgerWidth;
				position: absolute;
				display: block;
				content: '';
				transition: all $transitionTimeFast ease-in-out;
				background-color: $c-active;
				border-radius: rems(3px);
			}

			&:before {
				top: -10px;
			}

			&:after {
				bottom: -10px;
			}

			.mobileNav-isOpen & {
				background-color: transparent !important;

				&:before, &:after {
					top: 0;
				}

				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}
}

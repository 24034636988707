.stats {

	&-row {
		margin: 0;

		@include query_min($bp-mid) {
			> :nth-child(n+3) {
				border-top: 1px solid $c-gray2;
			}

			> :nth-child(2n) {
				border-left: 1px solid $c-gray2;
			}
		}
	}
}

.stat {
	width: 100%;
	padding: 0;

	@include query_max(rems($bp-mid-px - 1)) {
		& + & {
			margin-top: $marginDefault*1.5;
			padding-top: $paddingDefault*1.5;
			border-top: 1px solid $c-gray2;
		}
	}

	//border: 1px solid $c-gray2;
	@include query_min($bp-mid) {
		@include col--size(50%);
		@include scaling($paddingDefaultPx, $paddingDefaultPx*2, padding, $bp-mid-px, $bp-md-px);
	}

	&-value {
		font-family: $ff-tertiary;
		font-weight: normal;
		@include scaling-fs(30px, 95px, $bp-sm-px, $bp-md-px);
		line-height: (106/95);
		letter-spacing: .1em;
		display: block;
		text-transform: uppercase;
		margin: 0 auto;
	}

	&-label {
		font-family: $ff-secondary;
		@include scaling-fs(14px, 23px, $bp-sm-px, $bp-md-px);
		line-height: (31/23);
		letter-spacing: .16em;
		display: block;
		text-transform: uppercase;
		margin: $marginDefault auto 0;
	}
}
